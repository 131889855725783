
import { useEffect, useState } from "react";
import { Modal, Button, ButtonGroup, Checkbox } from "@appkit4/react-components";
import "./index.scss"

export const OwnEditModal = ({
  visible,
  title,
  checkedValues,
  ownOptions,
  onClose,
  checkedPlaceholder,
  onOk,
  ownType
}) => {

  const [editCheckedValues, setEditCheckedValues] = useState([]);

  useEffect(() => {
    setEditCheckedValues(checkedValues);

  }, [checkedValues])

  return (
    <Modal
      className="ownEditModal"
      title={title}
      visible={visible}
      onCancel={() => {
        onClose()
      }}
      footer={
        <ButtonGroup>
          <Button
            kind="secondary"
            className="cancelButton"
            onClick={() => {
              onClose()
            }}
          >
            キャンセル
          </Button>
          <Button 
            kind="secondary"
            className="saveButton"
            onClick={() => {
            onOk(editCheckedValues, ownType)
          }}>
            編集を完了する
          </Button>
        </ButtonGroup>
      }
    >

      <div className="checkedValues">
        {
          editCheckedValues.length > 0 && editCheckedValues.map(x => (
            <div
              className='checkedVal'
              key={x.value}
            >
              {x.label}
              <span 
                onClick={() => 
                  setEditCheckedValues(editCheckedValues => editCheckedValues.filter(y => y.value !== x.value)) 
                } 
                className="Appkit4-icon icon-circle-delete-fill"
              />
            </div>
          ))
        }
        { editCheckedValues.length === 0 && <div className='checkedPlaceholder'>{checkedPlaceholder}</div>}
      </div>
      <div className="options">
        {ownOptions.map(x => {
          const item = editCheckedValues.find(y => y.value === x.value);
          return <Checkbox
            className="ownCheckbox"
            key={x.value}
            checked={item !== undefined}
            onChange={(checked, event) => {
              if (checked) {
                setEditCheckedValues(editCheckedValues => [...editCheckedValues, x]);
              } else {
                setEditCheckedValues(editCheckedValues => editCheckedValues.filter(y => y.value !== x.value));
              }
            }}
          >
            {x.label}
          </Checkbox>
        })}
      </div>
    </Modal>
  )
}
