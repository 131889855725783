/**
 *-------------------------------------------
 * apis in common, routes.jsx, login page, register page, verify page...
 *-------------------------------------------
*/


import { getData, postData } from '../request'

export function getCompanyList(body) {
  return postData('/api/getCompanyPickList/', body)
}

export function getThemeList(body) {
  return postData('/api/getThemePickList/', body)
}   

export function getThemeList2(body) {
  return postData('/api/getThemePickList2/', body)
}   

export function login(body) {
  return postData('/api/login/', { ...body, cancelToken: null })
}

export function register(body) {
  return postData('/api/userRegister/', body)
}

export function printLog(body) {
  return postData('/api/getTabName/', { ...body, cancelToken: null })
}

export function loginOpenAM(body) {
  return postData('/api/loginOpenAM/', { ...body, cancelToken: null })
}

export function getProductIamId() {
  return postData('/api/getProductIamId/')
}

export function redirectIAM(body){
  return postData('/api/redirectIAM/', { ...body})
}

const openamUrl = process.env.REACT_APP_ENV === 'prod'
  ? 'https://signin.access.pwc.com'
  : 'https://signin.test.access.pwc.com'

export const authUrl = `${openamUrl}/openam/oauth2/realms/venturessso/authorize`

export const logoutUrl = `${openamUrl}/openam/XUI/?realm=%2Fventuressso#logout/&goto=${window.location.origin}/loginiam`

export const createLogoutUrl = (pathname = '/loginiam') => `${openamUrl}/openam/XUI/?realm=%2Fventuressso#logout/&goto=${window.location.origin}${pathname}`

export function checkEmailHasRegisted (body) {
  return postData('/api/checkIfUserRegister/', body)
} 

export function verifyChangeEmail (body) {
  return postData('/api/verifyChangEmail/', body)
} 

export function getChangeEmail (body) {
  return postData('/api/getChangeEmail/', body)
} 

export function logout (body) {
  return postData('/api/logout/', body)
}

export function getUserList () {
  return postData('/api/user/getUserList/')
}

export function checkusermail(body) {
  return postData('/api/admin/checkusermail', body)
}


// eslint-disable-next-line
export const eMailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const getUserInfo = (params, opts) => getData("/api/loginuser", params, opts)
