import axios from "axios"

import { store } from '../store'
import { updateUser } from '../actions/userInfo'
import { URLS, ERROR_CODE } from "../constants";
import { router, ROUTES_CONSTANT } from "../router";

const _axios = axios.create({
  baseURL: process.env.PUBLIC_URL
})

_axios.defaultSource = axios.CancelToken.source()

// Add a request interceptor
_axios.interceptors.request.use(
  (config) => {
    const state = store.getState()
    config.headers = {
      Token: state?.userInfo?.access_token,
      Uid: state?.userInfo?.uid,
      ...config.headers,
    };

    if (!config.data) config.data = {}

    // 1. config.cancelToken === null:  not need to abort
    // 2. other: auto set cancel token
    if (!config.cancelToken && config.cancelToken !== null) {
      config.cancelToken = _axios.defaultSource.token
    }
    return config;
    
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

const errorGuard = (err) => {
  const isError = err instanceof Error
  const response = isError ? err.response : err
  const error = isError ? err : {}
  const ret = isError ? Promise.reject(err) : err

  const state = store.getState()
  response?.data?.access_token && store.dispatch(updateUser({...state.userInfo, access_token: response?.data?.access_token }))

  if (!response?.data?.success) {
    if (response?.data?.reason === ERROR_CODE.invalidToken || error?.response?.status === parseInt(ERROR_CODE.invalidToken)) {
      
      store.dispatch(updateUser({}))
      window.location.href = URLS.login

      // cancel request by message
      _axios.defaultSource.cancel()

      // refresh default source
      _axios.defaultSource = _axios.CancelToken.source()

      return ret
    } 

    if (response?.data?.reason === ERROR_CODE.serverError || error?.response?.status === parseInt(ERROR_CODE.serverError)) {
      router.navigate(ROUTES_CONSTANT.SERVER_ERROR.path)
      return ret
    }

    if (response?.data?.reason === ERROR_CODE.notFound || error?.response?.status === parseInt(ERROR_CODE.notFound)) {
      router.navigate(ROUTES_CONSTANT.NOT_FOUND.path)
      return ret
    }

    if (!err.__CANCEL__) {

      router.navigate(ROUTES_CONSTANT.GENERAL_ERROR.path)
      return ret
    }
  }

  return ret
}

// Add a response interceptor
_axios.interceptors.response.use(errorGuard, errorGuard);

export const getData = (url, params, opts) => {
  return _axios({
    method: "GET",
    params,
    url,
    ...opts
  }).then((res) => res.data);
};

export const postData = (url, data) => {
  const options = {
    method: "POST",
    data,
    url,
  };
  return _axios(options).then((res) => res.data);
};

export const putData = (url, data) => {
  const options = {
    method: "PUT",
    data,
    url,
  };
  return _axios(options).then((res) => res.data);
};

export const patchData = (url, data) => {
  const options = {
    method: "PATCH",
    data,
    url,
  };
  return _axios(options).then((res) => res.data);
};

export const deleteData = (url, data) => {
  const options = {
    method: "DELETE",
    data,
    url,
  };
  return _axios(options).then((res) => res.data);
};

export const formPostData = (url, data) => {
  const options = {
    method: "POST",
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data,
    url,
  };
  return _axios(options).then((res) => res.data);
};

export default _axios
