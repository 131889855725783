import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router'
import { Table, Button, Column, Pagination } from '@appkit4/react-components'
import { ROUTES_CONSTANT } from '../../router';
import { getCompanies } from '../../api'
// import { message } from '../../components/Message';
import { Loading } from '../../components/Loading'
import './index.scss'

const PAGE_SIZE = 10;

export const TenantList = () => {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableTenantData, setTableTenantData] = useState([]);

  const tableTenantDataDisplay = useMemo(() => {
    return tableTenantData.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
  }, [tableTenantData, currentPage]);

  useEffect(() => {
    initCompanies();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initCompanies = async () => {
    try {
      setLoading(true);
      const { success, companies } = await getCompanies()
      // if (!success) return message.error(reason);
      success && setTableTenantData(companies);
    } finally {
      setLoading(false)
    }
  }

  return (
    <Loading className="tenantListContainer" visible={loading}>
      <span className='title'>テナント</span>
      <div>
        <Button className='newTenant' kind="Secondary" icon="icon-add-user-outline" onClick={() => {
          navigate(ROUTES_CONSTANT.TENANT_DETAIL.path, {
            state: {
              mode: 'new',
              record: {
                code: '',
                name: '',
                domain: '',
                mailfrom: '',
                mailto: '',
                replyto: '',
                is_alert_email_enable: 'False',
                route_path: '',
                rule_path: '01_JHI',
                language: 'jp',
                time_zone: 'Asia/Tokyo',
                country: [],
                industry: [],
              }
            }
          })
        }}>テナントを新規登録する</Button>
      </div>
      <div className='tableContainer'>
        <span className='tableTitle'>テナント一覧</span>
        <div className='tenantList'>
          <div className='listTable'>
            {
              tableTenantData.length ?
                <Table
                  originalData={tableTenantDataDisplay}
                  hasTitle
                  onRowClick={(event, index, data) => {
                    navigate(ROUTES_CONSTANT.TENANT_DETAIL.path, { state: { mode: 'view', code: data.code } })
                  }}
                >
                  <Column field='code' className='codeColumn'>顧客ID</Column>
                  <Column field='name' className='nameColumn'>企業名</Column>
                  <Column field='domain'>利用ドメイン</Column>
                </Table>
                : <div className='emptyTableText'>テナントがありません</div>
            }
          </div>
          <div className='listPagination'>
            <Pagination
              current={currentPage}
              total={Math.ceil(tableTenantData.length / PAGE_SIZE) || 1}
              onPageChange={page => {
                setCurrentPage(page)
              }}
              toPreviousPage='Previous'
              toNextPage='Next'
            />
          </div>
        </div>
      </div>
    </Loading>
  )
}