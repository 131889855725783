export const LOGIN_TOKEN = "t";
export const ADMIN_USER = 1;
export const COMMON_USER = 2;
export const DEFAULT_MODAL_ZINDEX = 1000; // the default zIndex of appkit modal

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE_SIZE_OPTIONS = [50, 100, 200];


export const SIM_STATUS_INIT = 1;
export const SIM_STATUS_IN_PROGRESS = 2;
export const SIM_STATUS_DONE = 3;
export const SIM_STATUS_STOPPING = 4;
export const SIM_STATUS_ERROR = 5;
export const SIM_STATUS_SUSPENSION = 6;
export const SIM_STATUS_LOCAL_OUTPUT_UPLOAD = 50;


export const UPLOAD_TYPE = {
  SOLUTION: "INVESTIGATION",
  TT: "TT",
  RULE: "RULES",
  EVENT: "EVENT",
  INCRPT: 'INCIDENT',
  THREAT_ACTOR: 'TA'
}

export const TT_TYPE = {
  standard: 'standard',
  pwc: 'pwc'
}



export const PROGRESS_STATUS = {
  NOT_AVAILABLE: "-1",
  INIT: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  RE_OPEN: 5
}

export const USER_ROLE = {
  user_role_normal: 1,
  user_role_maintain_admin: 2,
  user_role_system_admin: 3
} 

export const USER_ROLE_OPTIONS = [
  {
    role_id: USER_ROLE.user_role_normal,
    name: "一般ユーザー"
  },{
    role_id: USER_ROLE.user_role_maintain_admin,
    name: "保守管理者"
  }, {
    role_id: USER_ROLE.user_role_system_admin,
    name: "システム管理者"
  }

]

export const INFO_SPECIES = {
  maintenance: 1,
  version_up: 2,
  obstruction: 3,
  other:4
} 

export const INFO_SPECIES_OPTIONS = [
  {
    species_id: INFO_SPECIES.maintenance,
    name: "メンテナンス"
  },{
    species_id: INFO_SPECIES.version_up,
    name: "バージョンアップ"
  }, {
    species_id: INFO_SPECIES.obstruction,
    name: "障害/復旧"
  }, {
    species_id: INFO_SPECIES.other,
    name: "その他"
  }
]

export const INPUT_MAX_LENGTH = 50;
export const TEXTAREA_MAX_LENGTH = 200;

export const DEFAULT_TABLE_PAGE_OPTIONS = {
  striped: true,
  showPaginationTop: false,
  showPaginationBottom: true,
  sortable: true,
  defaultPageSize: 5
}
export const POSITION = "position";
export const CANCEL_REQUEST = 'CANCEL_REQUEST'

export const LANG_EN = 'en'
export const LANG_JP = 'jp'
export const I18NEXT_NAMESPACE = 'translation'

export const LANG_LIST = [
  { label: '日本語', value: LANG_JP, key: LANG_JP },
  { label: 'ENGLISH', value: LANG_EN, key: LANG_EN }
]

export const PLAN_SELECT_LIST = (t) => ([
  {
    id: 1,
    code: "0",
    kubun: "0",
    value: t(`signUpClient.contractPlanList.basic`),
  },
  {
    id: 2,
    code: "1",
    kubun: "1",
    value: t(`signUpClient.contractPlanList.advanced`),
  },
  {
    id: 3,
    code: "2",
    kubun: "2",
    value: t(`signUpClient.contractPlanList.managed`),
  },
])



export const POP_BANNER = {
  STATUS: {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "negative"
  },
  DURATION: 3000,
}

export const DOUBLE_LOGIN = 'DOUBLE_LOGIN'


export const PROJECT_CLOSE_STATUS = {
  CLOSE: "1",
  OPEN: "0",
}

// export const IMPORT_MODE = {
//   REPLACE: "upload_asset_mode_replace",
//   APPEND: "upload_asset_mode_append",
// }

export const IS_FAVORITED = "1"
export const IS_NOT_FAVORITED = "0"

export const NOTIFICATION_TYPE_DRAFT = "NOTIFICATION_DRAFT";
export const NOTIFICATION_TYPE_IMMEDIATE = "NOTIFICATION_IMMEDIATE";
export const NOTIFICATION_TYPE_NON_IMMEDIATE = "NOTIFICATION_NON_IMMEDIATE";
export const NOTIFICATION_STATUS_DRAFT = "0"
export const NOTIFICATION_STATUS_PUBLISHING = "1"
export const NOTIFICATION_STATUS_PUBLISHED = "2"

// Actions
export const ACTIONS = {
  GET_LANG: "GET_LANG",
  UPDATE_LANG: "UPDATE_LANG",

  UPDATE_OWN_COUNTRIES: "UPDATE_OWN_COUNTRIES",
  UPDATE_DEFAULT_OWN_COUNTRIES: "UPDATE_DEFAULT_OWN_COUNTRIES",

  UPDATE_OWN_INDUSTRIES: "UPDATE_OWN_INDUSTRIES",
  UPDATE_DEFAULT_OWN_INDUSTRIES: "UPDATE_DEFAULT_OWN_INDUSTRIES",

  GET_USERINFO: "GET_USERINFO",
  UPDATE_USERINFO: "UPDATE_USERINFO",

  UPDATE_TIMEZONE: "UPDATE_TIMEZONE",

  UPDATE_COMPANY_INFO: "UPDATE_COMPANY_INFO",

  UPDATE_TIMEZONE_OFFSET: "UPDATE_TIMEZONE_OFFSET"
}


export const TABLE_COLUMN_WIDTH = {
  "ID": 100,
  "user_name": 230,
  "project_name": 250,
  "simulation_name": 250,
  "group_name": 250,
  "mail_address": 300,
  "user_role": 150,
  "full_date": 175,
  "species": 150,
  "period": 250
}

export const INPUT_TYPE = 1;
export const OUTPUT_TYPE = 2;


export const M_TECH = "Technique";
export const M_TACTICS = "Tactic";


export const PLAY_STATE = {
  PLAYING: 'playing',
  PAUSED: 'paused',
  STOPPED: 'stopped',
};

export const SORT_OPTION_VALUE = {
  createdDate: '0',
  updatedDate: '1',
  severity: '2',
  status: '3'
}
export const SEVERITY_VALUE = {
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  info: 'Info',
  unknown: 'Unknown'
}

export const STATUS_VALUE = {
  unread: 'New',
  read: 'Opened',
  judged: 'Judged'
}

export const JUDGED_RESULT = {
  threat: 'Threat',
  overdetection: 'Overdetection'
}


export const SERVERITY_COLORS = {
  highColor : "#D15548",
  mediumColor : "#E8AAA3",
  lowColor : "#757575",
  infoColor : '#A3A3A3',
  unknownColor : '#D1D1D1',
  zeroColor : '#F3F3F3',
}

export const ERROR_CODE = {
  invalidToken: '454',
  serverError: '500',
  notFound: '404'
}

export const BASE_URL = process.env.PUBLIC_URL === '/' ? '' : process.env.PUBLIC_URL
export const URLS = {
  login: `${BASE_URL}/login`,
  logout: `${BASE_URL}/endSession`
}
