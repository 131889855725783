import moment from "moment";
import { USER_ROLE, BASE_URL } from "../constants";
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONSTANT } from "../router";


export function formatDates(time) {
  let newTime = time.split(" ")[0].split("-");
  return new Date(
    Number(newTime[0]),
    Number(newTime[1]) - 1,
    Number(newTime[2])
  );
}

export function wait(number) {
  return new Promise((resolve) => {
    setTimeout(resolve.bind(this), number);
  });
}

export const isNull = (obj) => {
  return obj === null || obj === undefined;
};

export const isEmpty = (obj) => {
  return obj === null || obj === undefined || obj === "";
};


export const getSearchString = (obj) => {
  let tempArr = []
  Object.keys(obj).forEach(e => {
    tempArr.push(`${e}=${obj[e]}`);
  })
  return `?${tempArr.join('&')}`;
}

const convetToLocaleLocale = (value) => {
  if(isEmpty(value)){
    return null;
  }else if(value && value.includes("Z")){
    return new Date(value);
  }else{
    return new Date(value + "Z");
  }
}

/** Convert UTC timestamp to a local time string representation. */
export const changeToLocalTime = (value) => {
  if(isEmpty(value)) {return "" };
  const localDate = convetToLocaleLocale(value);
  return moment(localDate.toLocaleString()).format("YYYY/MM/DD HH:mm:ss");
};

/** Convert UTC timestamp to a local time string representation. */
export const changeToLocalTimeFormat = (value) => {
  if(isEmpty(value)) {return "" };
  const localDate = convetToLocaleLocale(value);
  return moment(localDate.toLocaleString()).format("YYYY/MM/DD");
};

/** Convert UTC timestamp to a local time string representation. */
export const changeToLocalTimeFormat_v2 = (value) => {
  if(isEmpty(value)) {return "" };
  const localDate = convetToLocaleLocale(value);
  return moment(localDate.toLocaleString()).format("YYYY-MM-DD");
};

/** Convert local time  to a UTC timestamp string representation. */
export function dateToUTCString(dateObject) {
  if (!(dateObject instanceof Date)) {
    throw new Error("???");
  }

  return dateObject.toISOString();
}

//----------user permission related
export const isNormalUser = (userInfo) => {
  // eslint-disable-next-line
  return userInfo.role_id == USER_ROLE.user_role_normal;
}


export const isMaintainAdmin = (userInfo) => {
  // eslint-disable-next-line
  return userInfo.role_id == USER_ROLE.user_role_maintain_admin;
}


export const isSystemAdmin = (userInfo) => {
  // eslint-disable-next-line
  return userInfo.role_id == USER_ROLE.user_role_system_admin;
}


// -------------------go to page util----------------------
export const goTo404 = (navigate) => {
  navigate('/404');
}

export const goToAnalysisHome = (navigate) => {
  navigate(ROUTES_CONSTANT.ANALYSIS_HOME.path);
}

export const goToLogPage = (navigate) => {
  navigate(ROUTES_CONSTANT.LOG_PAGE.path);
}

export const goToReportPage = (navigate) => {
  navigate(ROUTES_CONSTANT.REPORT_PAGE.path);
}

export const goToAlertHome = (navigate) => {
  navigate(ROUTES_CONSTANT.ALERT_PAGE.path);
}

export const goToAlertHomeWithSeverity = (navigate, flag) => {
  const param = new URLSearchParams({ severity: flag }).toString();
  navigate(ROUTES_CONSTANT.ALERT_PAGE.path + '?' + param);
}

export const goToAlertHomeWithStatus = (navigate, flag) => {
  const param = new URLSearchParams({ status: flag }).toString();
  navigate(ROUTES_CONSTANT.ALERT_PAGE.path + '?' + param);
}

export const goToAlertHomeForMatrix = (navigate, infoObj) => {
  const param = new URLSearchParams(infoObj).toString();
  navigate(ROUTES_CONSTANT.ALERT_PAGE.path + '?' + param);
}

export const goToAlertHomeForMatrixInNewTab = (infoObj) => {
  const param = new URLSearchParams(infoObj).toString();
  const path = ROUTES_CONSTANT.ALERT_PAGE.path + '?' + param;
  window.open(`${BASE_URL}${path}`, '_blank');
}


export const goToAlertDetailInNewTab = (id) => {
  const path = ROUTES_CONSTANT.ALERT_DETAIL_PAGE.path.replace(':id', id)
  window.open(`${BASE_URL}${path}`, '_blank');
}

//-----------------------------------------------------

export const getFilterData = (originalData, filterKeys, listFilterMat) => {
  return originalData.filter((value) => {
    for (let ii = 0; ii < filterKeys.length; ii++) {
      // go through each filter key
      const key = filterKeys[ii];
      if (listFilterMat[key] && listFilterMat[key].length > 0) {
        // do the filtering
        const subfilters = listFilterMat[key];
        let vv = !isNull(value[key]) ? `${value[key]}`.toLowerCase() : null;
        // console.log(key, vv, subfilters);
        if (vv == null) {
          return false;
        }
        let pass = subfilters.some(e => {
          return vv.includes(e.toLowerCase());
        })
        if (!pass) {
          return false;
        }
      }
    }
    return true;
  });
}

export const getHomePath = (userInfo) => {
  let homePath = (userInfo && isMaintainAdmin(userInfo)) ? ROUTES_CONSTANT.REPORT_PAGE.path : ROUTES_CONSTANT.ANALYSIS_HOME.path;
  return homePath;
}


export const equals = (a, b) => {
  // eslint-disable-next-line
  return `${a}` == `${b}`;
}


//https://stackoverflow.com/questions/2998784/how-to-output-numbers-with-leading-zeros-in-javascript
export const padZero = (num, digits) => {
  digits = digits || 6;
  if (isNull(num)) {
    num = 0;
  }
  return String(num).padStart(digits, '0');
}



/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

// calculate width dynamically
export const computeTableColumnWidth = (tableData, columnKey, maxWidth, minWidth = 100, fontObj = "14px") => {
  let longestText = "";
  tableData.forEach(row => {
    if (!isNull(row[columnKey])) {
      const temp = `${row[columnKey]}`
      if (temp.length > longestText.length) {
        longestText = temp;
      }
    }
  })

  // if(longestText && columnKey == "group_name"){
  //   debugger
  // }
  // debugger
  let ms = getTextWidth(longestText, fontObj) + 60; //with padding
  let result = Math.max(ms, minWidth);
  result = Math.min(maxWidth, result);
  // result = Math.max(maxWidth, result);
  return result;
}

export const jumpTo = (link) => {
  // vera scan code being stupid
  // window.location.href = 
  const hkk = "h-r-e-f".replaceAll("-", "");
  const ll = window.location;
  ll[hkk] = link;
}

// https://stackoverflow.com/questions/3916191/download-data-url-file
export function downloadFile(dataurl, filename) {
  const link = document.createElement("a");
  // vera scan code being stupid
  // link.href = 
  const hkk = "h-r-e-f".replaceAll("-", "");
  link[hkk] = dataurl;
  link.download = filename;
  link.click();
}



// function isDateSmaller(startDate, endDate) {
//   startDate = new Date(Date.parse(startDate));
//   endDate = new Date(Date.parse(endDate));
//   return startDate < endDate;
// }

// // 時間の対比(開始時間が終了時間より小さいかどうかを対比)
// function isTimeSmaller(startTime, endTime) {
//   let compareResult = true
//   let startTimeList = startTime.split(":");
//   let endTimeList = endTime.split(":");
//   if (Number(startTimeList[0]) > Number(endTimeList[0])) {
//     // 時間数の比較
//     compareResult = false;
//   } else if (startTimeList[0] == endTimeList[0] && Number(startTimeList[1]) > Number(endTimeList[1])) {
//     // 時間数が同じ場合、分の数字を比較する
//     compareResult = false;
//   }
//   return compareResult;
// }


export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}


// /** 配列内のオブジェクトから、
//  * 指定したキーリストに基づいて必要な情報を抽出し、新しいオブジェクトを生成して、新しい配列として返す関数を作成する */
// export function extractObjects(arr, keys) {
//   arr = arr || [];
//   return arr.map(obj => {
//     let newObj = {};
//     for (let key of keys) {
//       if (key in obj) {
//         newObj[key] = obj[key];
//       }
//     }
//     return newObj;
//   });
// }

export function openURLInNewTab(url) {
  // 新しいタブを作成
  var newTab = window.open(`${BASE_URL}${url}`, '_blank');
  
  // ブラウザが新しいタブをサポートしており、ポップアップがブロックされていないか確認
  if (newTab) {
    newTab.focus(); // 新しいタブが正常に開かれた場合、それをフォーカスします
  } else {
    // ブラウザがポップアップウィンドウをブロックした場合、代替手段を提供できます。たとえば、現在のタブで開くことができます
    window.location.href = url;
  }
}

export const withRouter = (Component) =>{
  const Wrapper = (props) =>{
      const history = useNavigate();
      return <Component history={history} {...props}/>
  } 
  return Wrapper;
}

export const isString = val => typeof val === 'string'
