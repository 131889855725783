import { getData, postData, patchData } from "../request"

export const getCompanies = () => getData('/api/company/list')

export const getCompanyDetail = (code) => getData(`/api/company/${code}`)

export const createCompany = (code, body) => patchData(`/api/company/${code}`, body)

export const updateCompany = (code, body) => postData(`/api/company/${code}`, body)

export const getSettings = () => getData('/api/settings')