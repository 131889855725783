import { Error } from "../../components/Error";

export const ServerError = () => {

  return <Error
    title="ページを表示できません"
    desc="ただいまメンテナンス中です。しばらくしてから再度お試しください。<br>ご迷惑をおかけして申し訳ございません。"
    img="/resource/serverError.svg"
  />
}
