import { Table, Column, Pagination, Button } from '@appkit4/react-components'
import { useState, useMemo, useRef, useContext } from 'react';
import moment from 'moment'
import cs from 'classnames'
import { unparse } from 'papaparse'

import { getActors } from '../../../../api';
import { Context } from '../../Context';
import { downloadFile } from '../../../../utils/CommonFunction'
import { confirm } from '../../../../components/Confirm';
import { useTimezone } from '../../../../hooks';
import { LANG_EN, LANG_JP, TT_TYPE, UPLOAD_TYPE } from '../../../../constants';
import { Status } from './components/Status';
import { UploadModal } from './components/UploadModal'
import './index.scss'

const PAGE_SIZE = 10;

const LANG_MAP = {
  [LANG_EN]: '英語',
  [LANG_JP]: '日本語'
}

const CONTENT_TYPE_MAP = {
  Outline: '概要説明',
  Body: '本体'
}

export const UploadHistoryTable = ({
  data = [],
  type = ""
}) => {

  const { setLoading } = useContext(Context)

  const [storedTimezone] = useTimezone()
  const [modalVisible, setModalVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [ttType, setTtType] = useState(TT_TYPE.standard)

  const uploadTableRef = useRef(null)

  const tableDataDisplay = useMemo(() => {
    return data.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
  }, [data, currentPage]);

  const typeText = useMemo(() => {
    switch (type) {
      case UPLOAD_TYPE.SOLUTION:
        return '対応策'
      case UPLOAD_TYPE.TT:
        return 'Tactic and Technique'
      case UPLOAD_TYPE.RULE:
        return 'ルール'
      case UPLOAD_TYPE.EVENT:
        return '地政学イベント'
      case UPLOAD_TYPE.INCRPT:
        return 'インシデントレポート'
      case UPLOAD_TYPE.THREAT_ACTOR:
        return '脅威アクター'
      default: return ''
    }
  }, [type])

  const displayTime = time => time ? moment(time).tz(storedTimezone).format('YYYY/MM/DD HH:mm:ss') : ''

  const download = async () => {
    await confirm.warning('アクター一覧をダウンロードしますか。', 'アクター一覧のダウンロード')
    setLoading(true)
    const { success, actors } = await getActors().finally(() => setLoading(false))
    if (success) {
      const csvData = unparse(actors)

      // 防止乱码
      const BOM = "\uFEFF";
      const blobData = new Blob([BOM + csvData], {
        type: 'text/csv;charset=utf-8;',
      });
      const url = URL.createObjectURL(blobData);
      downloadFile(url, `${moment().format('YYYY-MM-DD HH-mm-ss')}.csv`)
      URL.revokeObjectURL(url);
    }
    
  }

  return <>

    <div className='uploadHistoryContainer'>
      {
        type === UPLOAD_TYPE.TT &&
        <div className='buttonGroup'>
          <button
            className={cs('ap-button button', { active: ttType === TT_TYPE.standard })}
            onClick={() => setTtType(TT_TYPE.standard)}
          >
            標準
          </button>
          <button
            className={cs('ap-button button', { active: ttType === TT_TYPE.pwc })}
            onClick={() => setTtType(TT_TYPE.pwc)}
          >
            PwCオリジナル
          </button>
        </div>
      }
      <div className='actions'>
        <Button
          className="upload-trigger-button"
          onClick={() => setModalVisible(true)}
          icon="icon-upload-light-outline"
        >
          {`${typeText}${type === UPLOAD_TYPE.TT ? ttType === TT_TYPE.pwc ? '（PwCオリジナル）' : '（標準）' : ''}をアップロード`}
        </Button>
        {type === UPLOAD_TYPE.THREAT_ACTOR && <Button
          onClick={() => download()}
          icon="icon-download-light-outline"
        >
          脅威アクターをダウンロードする
        </Button>}
      </div>

      <div className='tableContainer'>
        <span className='table-title'>{typeText}アップロード履歴</span>

        <div className="uploadTable" ref={uploadTableRef}>
          {data.length
            ? <Table
              originalData={tableDataDisplay}
              hasTitle
            >
              <Column
                className='uploadBeginDateColumn'
                field='start_datetime'
                renderCell={({ start_datetime }) => <span title={displayTime(start_datetime)}>{displayTime(start_datetime)}</span>}
              >
                アップロード開始日時
              </Column>
              <Column
                className='uploadEndDateColumn'
                field='end_datetime'
                renderCell={({ end_datetime }) => <span title={displayTime(end_datetime)}>{displayTime(end_datetime)}</span>}
              >
                アップロード終了日時
              </Column>
              <Column
                className='filenameColumn'
                field='file_name'
                renderCell={({ file_name }) => <span title={file_name}>{file_name}</span>}
              >
                ファイル名
              </Column>

              <Column
                className='userColumn'
                field='created_by'
                renderCell={({ created_by }) => <span title={created_by}>{created_by}</span>}
              >
                ユーザー
              </Column>

              {
                type === UPLOAD_TYPE.SOLUTION &&
                <Column
                  className='languageColumn'
                  field='file_language'
                  renderCell={({ file_language }) => <span title={LANG_MAP[file_language]}>{LANG_MAP[file_language]}</span>}
                >
                  言語
                </Column>
              }
              {
                type === UPLOAD_TYPE.TT &&
                <Column
                  className='contentTypeColumn'
                  field='content_type'
                  renderCell={({ content_type }) => <span title={CONTENT_TYPE_MAP[content_type]}>{CONTENT_TYPE_MAP[content_type]}</span>}
                >
                  ファイルの種類
                </Column>
              }
              <Column
                className='resultColumn'
                field='status'
                renderCell={({ status, result_text }) => <div className='resuleCell'>
                  <Status status={status} />
                  {result_text && <span title={result_text}> {`（${result_text}）`}</span>}
                </div>}
              >
                結果
              </Column>
            </Table>
            : <div className='emptyTableText'>履歴がありません</div>}
        </div>

        <div className='listPagination' data-mode="light">
          <Pagination
            current={currentPage}
            total={Math.ceil(data.length / PAGE_SIZE) || 1}
            onPageChange={page => {
              setCurrentPage(page)
              uploadTableRef.current?.scroll(0, 0)
            }}
            toPreviousPage='Previous'
            toNextPage='Next'
          />
        </div>
      </div>
    </div>

    <UploadModal
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      type={type}
      ttType={ttType}
    />
  </>

}
