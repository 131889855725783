import { useNavigate } from "react-router"

import { Error } from "../../components/Error";
import { ROUTES_CONSTANT } from "../../router"

export const NotFound = () => {

  const navigate = useNavigate()

  return <Error
    title="お探しのページが見つかりません"
    desc="リクエストされたページは存在しないか、移動された可能性があります。<br>入力したURLが正しいかどうか確認し、もう一度お試しください。"
    action={() => navigate(ROUTES_CONSTANT.UPLOAD.path, { replace: true })}
    actionName="ホームへ戻る"
  />
}
