import React, { useState, useEffect } from "react";
import { Loading as AppkitLoading } from "@appkit4/react-components";
import PropTypes from 'prop-types'

import styles from './loading.module.css'

let timer
export function Loading({
  isFullHeight,
  children,
  ...rest
}) {
  const [visible, setVisible] = useState(rest.visible);

  useEffect(() => {
    if (rest.delay) {
      timer && clearTimeout(timer);
      if (rest.visible) {
        timer = setTimeout(() => setVisible(true), rest.delay);
      } else {
        setVisible(false);
      }
    } else {
      setVisible(rest.visible);
    }
  }, [rest.visible, rest.delay]);
  
  return (
    <div className={`${isFullHeight ? "full-height" : ""} ${styles.loading}`} {...attrs(rest)}>
      {visible && <div className={`${styles.loadingContainer} loading-container`}
        >
          <AppkitLoading 
            className={styles.loadingContent}
            loadingType="circular"
            indeterminate
          />
      </div>}
      {children}
    </div>
  );
}

Loading.propTypes = {
  loading: PropTypes.bool,
  delay: PropTypes.number
}

const notAttrsList = ['visible']
const attrs = (props) => {
  const ret = {}
  Object.keys(props).forEach(k => {
    if (!notAttrsList.includes(k)) {
      ret[k] = props[k]
    }
  })
  return ret
}