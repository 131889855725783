import cs from 'classnames'
import { useMemo } from 'react'

import './index.scss'

export const Status = ({
  status
}) => {
  
  const statusClass = useMemo(() => {
    if (status === "アップロード完了") return "success"
    if (status === "アップロード失敗") return "fail"
    if (status === "アップロード中") return "processing"
  }, [status])

  return (
    <div className='statusCell' title={status}>
      <div className={cs("point", statusClass)} />
      <div>{status}</div>
    </div>
  )
}