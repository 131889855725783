import { Upload } from '@appkit4/react-components'
import { useState, forwardRef, useImperativeHandle } from 'react'

import './index.scss'

export const UploadField = forwardRef(({
  title,
  onFileChange = () => { },
  ...props
}, ref) => {

  const [fileList, setFileList] = useState([])

  const calFileSize = (fileSize) => {
    let result = ''
    if (fileSize >= 1048576) {
      // B => MB
      result = fileSize % 1048576 === 0 ? fileSize / 1048576 + 'MB' : (fileSize / 1048576).toFixed(2) + 'MB'
    } else {
      // B => KB
      result = fileSize % 1024 === 0 ? (fileSize / 1024).toFixed(2) + 'KB' : (fileSize / 1024).toFixed(2) + 'KB'
    }
    return result
  }

  useImperativeHandle(ref, () => ({
    clearFiles: () => setFileList([])
  }), [])

  return (
    <div className='uploadContainer'>
      {title && <span className='uploadTitle'>{title}</span>}
      {fileList.length
        ? <div className='ap-fileupload'>
          <div className='ap-fileupload-wrapper'>
            <div className="ap-fileupload-fileLists">
              {fileList.map(x => (
                <div
                  key={x}
                  className="ap-fileupload-fileList"
                >
                  <div className="ap-fileupload-loaded">
                    <span aria-hidden="true">
                      <span className="Appkit4-icon icon-document-text-outline" />
                    </span>
                    <span className="ap-fileupload-fileSpan" tabIndex="0" aria-label={x.name}>
                      <span className="ap-fileupload-fileName">
                        <span className="fileName" title=".npmrc">{x.name}</span>
                      </span>
                      <span className="ap-fileupload-fileSize">({calFileSize(x.size)})</span>
                    </span>
                    <span className="Appkit4-icon icon-circle-checkmark-outline" />
                  </div>
                </div>
              ))}
            </div>
            <div className="ap-fileupload-footer">
              <div className="ap-fileupload-footer-delete-attachment">
                <span
                  className="delDesc"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setFileList([])
                    onFileChange([])
                  }}
                >
                  ファイルを削除する
                </span>
              </div>
            </div>
          </div>
        </div>
        : <Upload
          description={() => (
            <span className="uploadDesc">ドラッグ＆ドロップ または <span className="link">ファイルを選択</span></span>
          )}
          onChange={(file, fileList) => {
            setFileList(fileList)
            onFileChange(fileList)
          }}
          {...props}
        />}

    </div>
  )
})
