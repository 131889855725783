import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Button,
  ButtonGroup,
  Input,
  Select,
} from "@appkit4/react-components";
import cs from 'classnames';
import { ROUTES_CONSTANT } from "../../router";
import { OwnEditModal } from "./components/OwnEditModal";
import { SaveConfirmModal } from "./components/SaveConfirmModal";
import {
  getCompanyDetail,
  getSettings,
  createCompany,
  updateCompany,
  getCompanies
} from "../../api";
import { Loading } from "../../components/Loading";
import { useUserInfo } from "../../hooks";
import "./index.scss";
import { TextArea } from "../../components/TextArea";

const fieldLabelValueMapping = {
  code: "顧客ID",
  name: "企業名",
  domain: "利用ドメイン",
  route_path: "ディレクトリ名",
  rule_path: "ルールファイル格納パス",
  mailfrom: "アラートメール送信元",
  mailto: "アラートメール送信先",
  replyto: "アラートメール返信先",
  is_alert_email_enable: "アラートメール送信設定",
  language: "言語",
  time_zone: "タイムゾーン",
  country: "国",
  industry: "業界",
};

const BELONGSFIELDS = ["country", "industry"];

const LANG_LIST = [
  { label: "Japanese", value: "jp" },
  { label: "English", value: "en" },
];

const ALERT_EMAIL_ENABLE_LIST = [
  { label: "On", value: "True" },
  { label: "Off", value: "False" },
];

export const TenantDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [storedUserInfo] = useUserInfo();

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("view"); // new | edit | view

  // const [fieldRequired, setFieldRequired] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const tenantDetailContainerRef = useRef(null)
  const fieldRefs = useRef([])

  const [ownModalVisible, setOwnModalVisible] = useState(false);
  const [ownModalTitle, setOwnModalTitle] = useState("");
  const [ownModalPlaceholder, setOwnModalPlaceholder] = useState("");
  const [ownModalType, setOwnModalType] = useState("");
  const [ownModalCheckedValues, setOwnModalCheckedValues] = useState([]);
  const [ownModalOwnOptions, setOwnModalOwnOptions] = useState([]);

  const [timezoneData, setTimezoneData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [industriesData, setIndustriesData] = useState([]);

  const [record, setRecord] = useState({});
  const [backupRecord, setBackupRecord] = useState({});

  const [error, setError] = useState({})

  const checkCode = async (value) => {
    if (!value) return '顧客IDを入力してください'
    if (mode === 'new' && value.length !== 12) return '12桁英数半角のみ入力可能です'
    const reg = /^[A-Za-z0-9]+$/
    if (!reg.test(value)) return '12桁英数半角のみ入力可能です'
    setLoading(true)
    const { success, companies } = await getCompanies().finally(() => setLoading(false))
    if (success && companies.some(x => x.code === value) && mode === 'new') return 'すでに登録されている顧客IDです。登録する顧客IDを再度ご確認ください。'
    return ''
  }

  const checkName = value => {
    if (!value) return '企業名を入力してください'
    return ''
  }

  const checkDomain = value => {
    if (!value) return 'ドメインを入力してください'
    const reg = /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    if (!reg.test(value)) return 'ルールに合致するもののみ入力可能です（メールアドレス＠以降の部分）'
    return ''
  }

  const checkDirectory = value => {
    if (!value) return 'ディレクトリ名を入力してください'
    const reg = /^[/A-Za-z0-9_-]+$/
    if (!reg.test(value)) return '英数半角小文字と"-"と"_"と"/"のみ入力可能です'
    return ''
  }

  const checkPath = value => {
    if (!value) return 'ルールファイル格納パスを入力してください'
    return ''
  }

  const checkMailFrom = value => {
    if (record['is_alert_email_enable'] === "True") {
      if (!value) return 'アラートメール送信元を入力してください'
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!reg.test(value)) return '正しいメールアドレスを入力してください'
    }
    return ''
  }

  const checkMailTo = value => {
    if (record['is_alert_email_enable'] === "True") {
      const values = value.split('\n')
      if (!value || !values.length) return 'アラートメール送信先を入力してください'
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!values.every(val => reg.test(val))) return '正しいメールアドレスを入力してください'
    }
    return ''
  }

  const checkMailReplyTo = value => {
    if (record['is_alert_email_enable'] === "True") {
      if (!value) return 'アラートメール返信先を入力してください'
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!reg.test(value)) return '正しいメールアドレスを入力してください'
    }
    return ''
  }

  const checkBelongsFields = value => {
    if (!value.length) return true
    return ''
  }

  const checkField = (key, value) => {
    switch(key) {
      case 'code':
        return checkCode(value)
      case 'name':
        return checkName(value)
      case 'domain':  
        return checkDomain(value)
      case 'route_path':
        return checkDirectory(value)
      case 'rule_path':
        return checkPath(value)
      case 'mailfrom':
        return checkMailFrom(value)
      case 'mailto':
        return checkMailTo(value)
      case 'replyto':
        return checkMailReplyTo(value)
      case 'country':
      case 'industry':
        return checkBelongsFields(value)
      default: 
        return ''
    }
  }

  const initErrorStatus = () => {
    setError(Object.fromEntries(Object.keys(fieldLabelValueMapping).map(key => [key, ''])))
  }

  const validate = async () => {
    let pass = true

    for (const key of Object.keys(fieldLabelValueMapping)) {
      const errorText = await checkField(key, record[key])
      setError(error => ({...error, [key]: errorText }))
      if (pass && errorText) pass = false
    }

    return pass
  }

  useEffect(() => {
    if (Object.values(error).filter(x => x).length) {

      // scroll to error field
      const targetIndex = Object.keys(error).findIndex(k => error[k])
      const top = (fieldRefs[targetIndex]?.offsetTop ?? 0) - (tenantDetailContainerRef.current?.offsetTop ?? 0)
      tenantDetailContainerRef.current?.scrollTo({
        left: 0,
        top,
        behavior: 'smooth'
      }) 
    }
  }, [error])

  useEffect(() => {
    fetchSetting();
    initErrorStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state !== null && state.mode) {
      setMode(state.mode);
      if (state.mode === "new") {
        setRecord(state.record);
        setBackupRecord(state.record);
      } else {
        fetchCompany(state.code);
      }
    }
  }, [state]);

  const fetchSetting = async () => {
    try {
      setLoading(true);

      const {
        success,
        countries,
        industries,
        time_zones,
      } = await getSettings();
      if (!success) return;
      const newCountries = countries.map((item) => ({
        label: item.name,
        value: item.code,
      }));
      setCountriesData(newCountries);
      const newIndustries = industries.map((item) => ({
        label: item,
        value: item,
      }));
      setIndustriesData(newIndustries);
      const newTimezones = time_zones.map((item) => ({
        label: item.label,
        value: item.time_zone,
      }));
      setTimezoneData(newTimezones);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompany = async (code) => {
    try {
      setLoading(true);

      const { success, company } = await getCompanyDetail(code);
      if (!success) return;
      const {
        created_by,
        created_date,
        last_updated_by,
        last_updated_date,
        ...companyRest
      } = company;
      companyRest.country = company.country.split(", ");
      companyRest.industry = company.industry.split(", ");
      setRecord(companyRest);
      setBackupRecord(companyRest);
    } finally {
      setLoading(false);
    }
  };

  const createTeant = async () => {
    try {
      setLoading(true);
      const tenantRequestBody = { ...record };
      tenantRequestBody.operator_name = storedUserInfo.name;
      tenantRequestBody.country = record.country.join(", ");
      tenantRequestBody.industry = record.industry.join(", ");
      const { success } = await createCompany(record.code, tenantRequestBody);
      if (!success) return;
    } finally {
      setLoading(false);
      setMode("view");
      setBackupRecord(record);
      navigate(ROUTES_CONSTANT.TENANT_DETAIL.path, {
        state: { mode: "view", code: record.code },
      });
    }
  };

  const updateTeant = async () => {
    try {
      setLoading(true);
      const tenantRequestBody = { ...record };
      tenantRequestBody.operator_name = storedUserInfo.name;
      tenantRequestBody.country = record.country.join(", ");
      tenantRequestBody.industry = record.industry.join(", ");
      const { success } = await updateCompany(record.code, tenantRequestBody);
      if (!success) return;
    } finally {
      setLoading(false);
      setMode("view");
      setBackupRecord(record);
    }
  };

  const saveTenant = () => {
    if (mode === "new") {
      createTeant();
    } else {
      updateTeant();
    }
  };

  const openOwnModal = (key) => {
    let ownTitle = "",
      placeholder = "",
      ownOptions = [],
      checkedValues = [];
    if (key === "country") {
      ownTitle = "所属国の設定";
      placeholder = "国を選択してください";
      checkedValues = record.country.map((x) => {
        const contryItem = countriesData.find((y) => y.value === x);
        return { label: contryItem.label, value: x };
      });
      ownOptions = countriesData;
    } else {
      ownTitle = "所属業界の設定";
      placeholder = "業界を選択してください";
      checkedValues = record.industry.map((x) => {
        return { label: x, value: x };
      });
      ownOptions = industriesData;
    }
    setOwnModalTitle(ownTitle);
    setOwnModalPlaceholder(placeholder);
    setOwnModalCheckedValues(checkedValues);
    setOwnModalOwnOptions(ownOptions);
    setOwnModalVisible(true);
    setOwnModalType(key);

    if (mode === "new") {
      setMode("new");
    } else {
      setMode("edit");
    }
  };

  const clearOwnModal = () => {
    setOwnModalTitle("");
    setOwnModalPlaceholder("");
    setOwnModalType("country");
    setOwnModalCheckedValues([]);
    setOwnModalOwnOptions([]);
    setOwnModalVisible(false);
  };

  const fieldDisplayValue = (key) => {
    const langItem = LANG_LIST.find((x) => x.value === record[key]);
    const timezoneItem = timezoneData.find((x) => x.value === record[key]);
    const alertemailItem = ALERT_EMAIL_ENABLE_LIST.find(
      (x) => x.value === record[key]
    );
    if (langItem) {
      return langItem.label;
    } else if (timezoneItem) {
      return timezoneItem.label;
    } else if (alertemailItem) {
      return alertemailItem.label;
    } else {
      return record[key];
    }
  };
  const ownDisplayValue = (fieldValue) => {
    const contryItem = countriesData.find((x) => x.value === fieldValue);
    const industryItem = industriesData.find((x) => x.value === fieldValue);
    if (contryItem) {
      return contryItem.label;
    } else if (industryItem) {
      return industryItem.label;
    } else {
      return fieldValue;
    }
  };

  // const belongRequiredColor = () => {
  //   if (fieldRequired) {
  //     return "#c52a1a";
  //   } else {
  //     return "#696969";
  //   }
  // };

  const getDisabledStatus = (key) => {
    if (key === "code") {
      return mode === "edit"
    }
    if (['mailfrom', 'mailto', 'replyto'].includes(key)) return record['is_alert_email_enable'] === "False"
    return false
  }

  const basicFieldValue = (label, key) => {
    if (mode === "edit" || mode === "new") {
      const cloneRecord = { ...record };
      if (key === "language") {
        return (
          <Select
            className="inputFieldValue"
            data={LANG_LIST}
            hideTitleOnInput
            value={cloneRecord[key]}
            onSelect={(vals) => {
              cloneRecord[key] = vals;
              setRecord(cloneRecord);
            }}
          />
        );
      } else if (key === "time_zone") {
        return (
          <Select
            className="inputFieldValue"
            data={timezoneData}
            searchable={true}
            hideTitleOnInput
            searchPlaceholder={label + "を検索する"}
            value={cloneRecord[key]}
            onSelect={(vals) => {
              cloneRecord[key] = vals;
              setRecord(cloneRecord);
            }}
          />
        );
      } else if (key === "is_alert_email_enable") {
        return (
          <Select
            className="inputFieldValue"
            data={ALERT_EMAIL_ENABLE_LIST}
            hideTitleOnInput
            value={cloneRecord[key]}
            onSelect={(vals) => {
              cloneRecord[key] = vals;
              setRecord(cloneRecord);
            }}
          />
        );
      } else if (key === "mailto") {
        return (
          <TextArea
            autosize
            className={cs("inputFieldValue", { error: error[key] })}
            hideTitleOnInput
            value={cloneRecord[key]}
            onKeyDown={e => {
              const line = e.target.value.split('\n').length
              line > 9 && e.code === 'Enter' && e.preventDefault()
            }}
            onChange={(value, event) => {
              cloneRecord[key] = value;
              setRecord(cloneRecord);
            }}
            disabled={getDisabledStatus(key)}
            error={
              // fieldRequired &&
              !(mode === "edit" && key === "code") &&
              error[key]
            }
            errorNode={
              <div className="inputErrorMsg">{error[key]}</div>
            }
          />
        );
      } else {
        return (
          <Input
            className="inputFieldValue"
            type={"text"}
            disabled={getDisabledStatus(key)}
            hideTitleOnInput
            value={cloneRecord[key]}
            onChange={(value, event) => {
              cloneRecord[key] = value;
              setRecord(cloneRecord);
            }}
            error={
              // fieldRequired &&
              !(mode === "edit" && key === "code") &&
              error[key]
            }
            errorNode={
              <div className="inputErrorMsg">{error[key]}</div>
            }
          />
        );
      }
    }
    // mode === 'view'
    else {
      return <span className="basicFieldValue">{fieldDisplayValue(key)}</span>;
    }
  };

  const headerButtons = () => {
    if (mode === "view") {
      return (
        <Button
          kind="Secondary"
          className="editButton"
          onClick={() => {
            setMode("edit");
            initErrorStatus()
            // setFieldRequired(false);
          }}
        >
          編集する
        </Button>
      );
    } else if (mode === "edit") {
      return (
        <ButtonGroup>
          <Button
            kind="Secondary"
            className="cancelButton"
            onClick={() => {
              setMode("view");
              // setFieldRequired(false);
              initErrorStatus()
              setRecord({ ...backupRecord });
            }}
          >
            キャンセル
          </Button>
          <Button
            kind="Secondary"
            className="saveButton"
            onClick={async () => {
              // const fieldKey = Object.keys(record).find(
              //   (key) =>
              //     !(mode === "edit" && key === "code") &&
              //     (record[key] === undefined ||
              //       record[key] === "" ||
              //       record[key].length === 0)
              // );
              // if (fieldKey) {
              //   setFieldRequired(true);
              // } else {
              //   setSaveModalVisible(true);
              // }
              await validate() && setSaveModalVisible(true)
            }}
          >
            保存する
          </Button>
        </ButtonGroup>
      );
    }
    // mode === 'new'
    else {
      // nothing
    }
  };

  const bottomButtons = () => {
    if (mode === "new") {
      return (
        <div className="bottomButtons">
          <ButtonGroup>
            <Button
              kind="Secondary"
              className="cancelButton"
              onClick={() => {
                navigate(ROUTES_CONSTANT.TENANT_LIST.path);
              }}
            >
              キャンセル
            </Button>
            <Button
              kind="Secondary"
              className="saveButton"
              onClick={async () => {
                // const fieldKey = Object.keys(record).find(
                //   (key) =>
                //     !(mode === "edit" && key === "code") &&
                //     (record[key] === undefined ||
                //       record[key] === "" ||
                //       record[key].length === 0)
                // );
                // if (fieldKey) {
                //   setFieldRequired(true);
                // } else {
                //   setSaveModalVisible(true);
                // }
                await validate() && setSaveModalVisible(true)
              }}
            >
              保存する
            </Button>
          </ButtonGroup>
        </div>
      );
    }
  };

  return (
    <div className="tenantDetail" ref={tenantDetailContainerRef}>
      {state !== null && state !== undefined ? (
        <Loading className="tenantDetailContainer" visible={loading}>
          <span className="title">
            {mode === "new" ? "テナント新規登録" : record.name}
          </span>

          <div className="basicContainer">
            <div className="basicHeader">
              <span className="basicTitle">基本情報</span>
              {headerButtons()}
            </div>
            <div className="basicContent">
              {Object.keys(fieldLabelValueMapping)
                .filter((x) => !BELONGSFIELDS.includes(x))
                .map((key, i) => (
                  <div className="basicField" key={key} ref={fieldRef => fieldRefs[i] = fieldRef}>
                    <span className="basicFieldLabel">
                      {fieldLabelValueMapping[key]}
                    </span>
                    {basicFieldValue(fieldLabelValueMapping[key], key)}
                  </div>
                ))}
            </div>
          </div>

          <div className="belongsContainer">
            <div className="belongsHeader">
              <span className="belongsTitle">所属</span>
            </div>
            <div className="belongsContent">
              {BELONGSFIELDS.map((key, i) => (
                <div className="ownItem" key={key} ref={fieldRef => fieldRefs[Object.keys(fieldLabelValueMapping).length - 2 + i] = fieldRef}>
                  <span className="ownTitle">
                    {fieldLabelValueMapping[key]}
                  </span>
                  <div className="ownContent">
                    <div className="ownValGroup">
                      {record[key]?.length ? (
                        record[key].map((x) => (
                          <div key={x} className="ownVal">
                            {ownDisplayValue(x)}
                          </div>
                        ))
                      ) : (
                        <span
                          className="ownPlaceholder"
                          style={{ color: error[key] ? '#c52a1a' : '#696969' }}
                        >
                          所属{fieldLabelValueMapping[key]}を登録してください
                        </span>
                      )}
                    </div>
                    <span
                      className="Appkit4-icon icon-edit-outline"
                      onClick={() => {
                        openOwnModal(key);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {bottomButtons()}
        </Loading>
      ) : (
        <div>テナント情報がありません</div>
      )}

      <SaveConfirmModal
        visible={saveModalVisible}
        onClose={() => {
          setSaveModalVisible(false);
        }}
        onOk={() => {
          setSaveModalVisible(false);
          saveTenant();
        }}
        onNoSave={() => {
          if (mode === "new") {
            navigate(ROUTES_CONSTANT.TENANT_LIST.path);
          } else {
            setMode("view");
            initErrorStatus()
            setRecord({ ...backupRecord });
            setSaveModalVisible(false);
          }
        }}
      />

      <OwnEditModal
        visible={ownModalVisible}
        title={ownModalTitle}
        checkedValues={ownModalCheckedValues}
        ownOptions={ownModalOwnOptions}
        checkedPlaceholder={ownModalPlaceholder}
        onClose={() => {
          clearOwnModal();
        }}
        onOk={(finalCheckedValues, ownType) => {
          if (ownType === "country") {
            setRecord({
              ...record,
              country: finalCheckedValues.map((x) => x.value),
            });
          } else {
            setRecord({
              ...record,
              industry: finalCheckedValues.map((x) => x.value),
            });
          }
          clearOwnModal();
        }}
        ownType={ownModalType}
      />
    </div>
  );
};
