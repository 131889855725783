import { App } from '../App';
import { Navigate, createBrowserRouter } from "react-router-dom";

import { Logout } from '../pages/Logout';

import { Upload } from '../pages/Upload';
import { TenantList } from '../pages/TenantList';
import { TenantDetail } from '../pages/TenantDetail';
import { GeneralError } from '../pages/GeneralError'
import { NotFound } from '../pages/NotFound';
import { ServerError } from '../pages/ServerError';

import { ServiceUnavailable } from '../pages/ServiceUnavailable';
import { ErrorLayout } from './ErrorLayout';  

// https://reactrouter.com/en/main/routers/create-browser-router

// interface RouteObject {
//   path?: string;
//   index?: boolean;
//   children?: React.ReactNode;
//   caseSensitive?: boolean;
//   id?: string;
//   loader?: LoaderFunction;
//   action?: ActionFunction;
//   element?: React.ReactNode | null;
//   Component?: React.ComponentType | null;
//   errorElement?: React.ReactNode | null;
//   ErrorBoundary?: React.ComponentType | null;
//   handle?: RouteObject["handle"];
//   shouldRevalidate?: ShouldRevalidateFunction;
//   lazy?: LazyRouteFunction<RouteObject>;
// }

export const ROUTES_CONSTANT = {
  UPLOAD: {
    path: "/upload",
    displayNameI18n: "",
  },
  TENANT_LIST: {
    path: "/tenant-list",
    displayNameI18n: "",
  },
  TENANT_DETAIL: {
    path: "/tenant-detail",
    displayNameI18n: "",
  },
  NOT_FOUND: {
    path: '/404',
    displayNameI18n: ''
  },
  SERVER_ERROR: {
    path: '/500',
    displayNameI18n: ''
  },
  LOGOUT: {
    path: '/logout',
    displayNameI18n: ''
  },
  GENERAL_ERROR: {
    path: '/error',
    displayNameI18n: ''
  },
  SERVICE_UNAVAILABLE: {
    path: '/service-unavailable',
    name: '',
  }
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: ROUTES_CONSTANT.UPLOAD.path,
        element: <Upload />
      },
      {
        path: ROUTES_CONSTANT.TENANT_LIST.path,
        element: <TenantList />
      },
      {
        path: ROUTES_CONSTANT.TENANT_DETAIL.path,
        element: <TenantDetail />
      },
      {
        path: ROUTES_CONSTANT.NOT_FOUND.path,
        element: <NotFound />
      },
      {
        path: ROUTES_CONSTANT.SERVER_ERROR.path,
        element: <ServerError />
      },
      {
        path: ROUTES_CONSTANT.GENERAL_ERROR.path,
        element: <GeneralError />
      },
      {
        path: '/',
        element: <Navigate to={ROUTES_CONSTANT.UPLOAD.path} />
      },
      {
        path: '*',
        element: <Navigate to={ROUTES_CONSTANT.NOT_FOUND.path} />
      }
    ]
  },
  {
    path: '/',
    element: <ErrorLayout />,
    children: [
      {
        path: ROUTES_CONSTANT.LOGOUT.path,
        element: <Logout />
      },
      {
        path: ROUTES_CONSTANT.SERVICE_UNAVAILABLE.path,
        element: <ServiceUnavailable />
      },
      {
        path: '*',
        element: <Navigate to={ROUTES_CONSTANT.NOT_FOUND.path} />
      }
    ]
  },
], {
  basename: process.env.PUBLIC_URL
})