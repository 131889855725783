import { TextArea as AppkitTextArea } from '@appkit4/react-components'
import cs from 'classnames'

export const TextArea = ({
  error,
  errorNode,
  ...props
}) => {
  

  return <div className='customTextArea'>
    <AppkitTextArea className={cs({ error })} {...props} />
    { error && errorNode }
  </div>
} 
