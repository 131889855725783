// import { useEffect, useState } from "react";
import { Modal, Button, ButtonGroup } from "@appkit4/react-components";
import "./index.scss"

export const SaveConfirmModal = ({
  visible,
  onClose,
  onOk,
  onNoSave,
}) => {

  return (
    <Modal
      className='saveConfirmModal'
      visible={visible}
      title={'変更を保存しますか？'}
      onCancel={() => {
        onClose()
      }}
      footer={
        <ButtonGroup>
          <Button kind='Secondary' className='saveButton' onClick={() => {
            onOk();
          }}>保存する</Button>
          <Button kind='Secondary' className='noSaveButton' onClick={() => {
            onNoSave();
          }}>保存しない</Button>
          <Button kind='Secondary' className='cancelButton' onClick={() => {
            onClose();
          }}>キャンセル</Button>
        </ButtonGroup>
      }
    >

    </Modal>
  )
}