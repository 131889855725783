import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Header, Footer, Navigation, toaster, PanelButton } from "@appkit4/react-components";

import { confirm } from './components/Confirm'
import { getUserInfo } from "./api";
import { Loading } from "./components/Loading";
import { useUserInfo, useTimezone } from "./hooks";
import { ROUTES_CONSTANT } from "./router";
import { URLS } from "./constants";

import "./App.scss";
import "./common.scss"

export const App = () => {

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [collapse, setCollapse] = useState(true)
  const [searchParams] = useSearchParams()

  const [storedUserInfo, dispatchUserInfo] = useUserInfo()
  const [, dispatchTimezone] = useTimezone()

  const Logout = () => (
    <ul className="logoutContainer">
      <li onClick={logout}>
        <span className="Appkit4-icon icon-log-out-outline" />
        <span className="label">ログアウト</span>
      </li>
    </ul>
  )

  const logout = async () => {
    try {
      await confirm.withCustomStatus('', 'ログアウトしますか？', '', {
        okText: "ログアウト",
        cancelText: "キャンセル"
      })
      dispatchUserInfo({})
      window.location.href = `${URLS.logout}?access_token=${storedUserInfo.access_token}`
    } catch (err) { }
  }

  const checkToken = async () => {

    // back
    const access_token = searchParams.get('access_token')
    if (access_token) {
      const { uid, time_zone, success, name } = await getUserInfo(undefined, {
        headers: { 
          Token: access_token,
          Islogin: true
        }
      })
      if (!success) {
        return navigate(ROUTES_CONSTANT.SERVICE_UNAVAILABLE.path)
      }
      dispatchUserInfo({ access_token, uid, name })
      dispatchTimezone(time_zone)
      navigate(ROUTES_CONSTANT.UPLOAD.path)
      return
    }

    // go to login
    if (!storedUserInfo.access_token) {
      window.location.href = URLS.login
    }
  }

  const setMenuTitle = () => {
    const items = document.querySelectorAll('.ap-navigation-wrapper .ap-nav-item')
    if (items.length) {
      for (const item of items) {
        const name = item.querySelector('.Appkit4-icon')?.getAttribute('aria-label') || ''
        item.title = collapse ? name : ''
      }
    }
    
    const collapseItem = document.querySelector('.ap-navigation-footer')
    if (collapseItem) {
      const title = collapseItem.querySelector('.itemContent').innerHTML
      collapseItem.title = collapse ? title : ''
    }
  }

  useEffect(() => {
    setMenuTitle()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse])

  useEffect(() => {
    checkToken()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {

    // init message banner: 
    // There is a certain probability that the message banner of appkit4 will not display when first called, maybe is it a bug ?
    toaster.notify(<></>)
  }, [])


  
  const gotoHome = () => navigate(ROUTES_CONSTANT.UPLOAD.path);

  return storedUserInfo.access_token
    ? <>
      <Header
        className="mtid-app-header"
        type="transparent"
        onClickLogo={gotoHome}
        titleTemplate={() => {
          return (<div onClick={gotoHome} className="sub-pwc-logo">Managed Threat Intelligence & Detection | Admin</div>);
        }}
        userTemplate={() => (
          <PanelButton
            className="avatar"
            popupElement={<Logout />}
          >
            {storedUserInfo?.uid?.slice(0, 2)}
          </PanelButton>
        )}
      >
      </Header>
      <div className="appMain">
        <Navigation
          width={280}
          hasHeader={false}
          navList={[
            {
              name: 'Home',
              prefixIcon: 'upload',
              path: ROUTES_CONSTANT.UPLOAD.path,
              itemClassName: pathname === ROUTES_CONSTANT.UPLOAD.path && 'selected'
            },
            {
              name: 'Tenant List',
              prefixIcon: 'account-box',
              path: ROUTES_CONSTANT.TENANT_LIST.path,
              itemClassName: pathname === ROUTES_CONSTANT.TENANT_LIST.path && 'selected'
            }
          ]}
          onClickCollapseEvent={bl => setCollapse(bl)}
          onClickItem={(e, { path }) => navigate(path)}
          collapsed={collapse}
        >
        </Navigation>
        <div className="appContent">
          <Outlet />
          <Footer
            content="© 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a class='ap-link' href='https://www.pwc.com/structure' 
target='_blank'>www.pwc.com/structure</a> for further details."
            type="text"
          />
        </div>
      </div>

    </>
    : <Loading visible isFullHeight />
}
