import { formPostData, getData } from "../request";
import { UPLOAD_TYPE } from "../constants";

export const getTenentList = () => getData('/api/company/list')

export const uploadRules = body => formPostData('/api/rules/upload', body)

export const getSolutionData = () => getData(`/api/upload_history?upload_type=${UPLOAD_TYPE.SOLUTION}`)

export const getTTData = () => getData(`/api/upload_history?upload_type=${UPLOAD_TYPE.TT}`)

export const getRuleData = () => getData(`/api/upload_history?upload_type=${UPLOAD_TYPE.RULE}`)

export const getEventData = () => getData(`/api/upload_history?upload_type=${UPLOAD_TYPE.EVENT}`)

export const getIncRptData = () => getData(`/api/upload_history?upload_type=${UPLOAD_TYPE.INCRPT}`)

export const getThreatActorData = () => getData(`/api/upload_history?upload_type=${UPLOAD_TYPE.THREAT_ACTOR}`)

export const uploadTT = body => formPostData(`/api/tactic_tech/upload`, body)

export const uploadPwCTT = body => formPostData(`/api/pwc_tech/upload`, body)

export const uploadSolution = body => formPostData(`/api/investigation/upload`, body)

export const uploadEvent = body => formPostData(`/api/event_report/upload`, body)

export const uploadIncRpt = body => formPostData('/api/incident_report/upload', body)

export const uploadThreatActor = body => formPostData('/api/actors/description', body)

export const getActors = () => getData('/api/actors')
