import { Navigate } from 'react-router-dom'

import { ROUTES_CONSTANT } from '../../router'
import { useUserInfo } from '../../hooks'
import { Error } from "../../components/Error";
import { URLS } from '../../constants'

export const Logout = () => {

  const [userInfo] = useUserInfo()

  return userInfo.access_token
    ? <Navigate to={ROUTES_CONSTANT.ANALYSIS_HOME.path} />
    : <Error
      title="ログアウトに成功しました。"
      action={() => window.location.href = URLS.login}
      actionName="ログイン"
    />
}
