import { Modal, Badge } from "@appkit4/react-components";

const basicOptions = {
	title: "確認",
	ariaLabel: "確認",
	cancelshow: true,
	maskCloseable: true,
	closeOnPressEscape: true,
	footerStyle: { paddingTop: "8px", marginTop: "-8px", minHeight: "64px" },
	icons: false,
	onOpen: () => { },
	onClose: () => { },
	onOk: () => { },
	header: false,
	okshow: true,
	okText: "はい",
	cancelText: "いいえ",
	content: "確認しますか。",
};

const CANCEL = 'cancel'

export class Confirm {
	constructor(options = {}) {
		this.$options = { ...basicOptions, ...options };
	}

	success(content = this.$options.content, title = this.$options.title, options = {}) {
		return new Promise((resolve, reject) => {
			const modal = Modal.confirm({
				...this.$options,
				content,
				title,
				...options,
				header: <Badge type="success" value="Success" />,
				onOk: () => {
					this.$options.onOk?.();
					options?.onOk?.();
					resolve(modal);
				},
				onClose: () => {
					this.$options.onClose?.();
					options?.onClose?.();
					reject(CANCEL);
				},
			});
		});
	}

	error(content = this.$options.content, title = this.$options.title, options = {}) {
		return new Promise((resolve, reject) => {
			const modal = Modal.confirm({
				...this.$options,
				content,
				title,
				...options,
				header: <Badge type="danger" value="error" />,
				onOk: () => {
					this.$options.onOk?.();
					options?.onOk?.();
					resolve(modal);
				},
				onClose: () => {
					this.$options.onClose?.();
					options?.onClose?.();
					reject(CANCEL);
				},
			});
		});
	}

	warning(content = this.$options.content, title = this.$options.title, options = {}) {
		return new Promise((resolve, reject) => {
			const modal = Modal.confirm({
				...this.$options,
				content,
				title,
				...options,
				header: <Badge type="warning" value="warning" />,
				onOk: () => {
					this.$options.onOk?.();
					options?.onOk?.();
					resolve(modal);
				},
				onClose: () => {
					this.$options.onClose?.();
					options?.onClose?.();
					reject(CANCEL);
				},
			});
		});
	}

	withCustomStatus(content = this.$options.content, title = this.$options.title, header, options = {}) {
		return new Promise((resolve, reject) => {
			const modal = Modal.confirm({
				...this.$options,
				content,
				title,
				...options,
				header,
				onOk: () => {
					this.$options.onOk?.();
					options?.onOk?.();
					resolve(modal);
				},
				onClose: () => {
					this.$options.onClose?.();
					options?.onClose?.();
					reject(CANCEL);
				},
			});
		});
	}
}

export const confirm = new Confirm()
