import { ACTIONS } from "../constants";

const initialState = {};

export function userInfo(state = initialState, actions) {
  switch (actions.type) {
    case ACTIONS.GET_USERINFO:
      return actions.userInfo;
    case ACTIONS.UPDATE_USERINFO:
      return actions.userInfo;
    default:
      return state;
  }
}
