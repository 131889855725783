import { Button } from '@appkit4/react-components';
import './index.scss';

export const Error = ({
  title,
  desc,
  img = '/resource/error.svg',
  action,
  actionName,
}) => {

  return (
    <div className="errorPage">
      <img src={img} className="error-image" alt="error" />
      <div className='textContainer'>
        <h1 className="title">{title}</h1>
        <span className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
      {action && <Button onClick={() => typeof action === "function" && action()}>{actionName}</Button>}
    </div>
  );
}
