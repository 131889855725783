/**
 *-------------------------------------------
 * create userInfoActions actions in redux
 *-------------------------------------------
*/


import { ACTIONS } from "../constants";

export const getUser = () => {
  return {
    type: ACTIONS.GET_USERINFO,
  };
};

export const updateUser = (userInfo) => {
  return {
    type: ACTIONS.UPDATE_USERINFO,
    userInfo,
  };
};
