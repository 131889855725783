import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Header, /*PanelButton,*/ Footer, toaster } from "@appkit4/react-components";

// import { URLS } from "../constants";
// import { confirm } from "../components/Confirm";
// import { useUserInfo } from "../hooks";

import "../App.scss";
import "../common.scss"

export const ErrorLayout = () => {

  // const [storedUserInfo, dispatchUserInfo] = useUserInfo()

  // const Logout = () => (
  //   <ul className="logoutContainer">
  //     <li onClick={logout}>
  //       <span className="Appkit4-icon icon-log-out-outline" />
  //       <span className="label">ログアウト</span>
  //     </li>
  //   </ul>
  // )

  // const logout = async () => {
  //   try {
  //     await confirm.withCustomStatus('', 'ログアウトしますか？', '', {
  //       okText: "ログアウト",
  //       cancelText: "キャンセル"
  //     })
  //     dispatchUserInfo({})
  //     window.location.href = `${URLS.logout}?id_token=${storedUserInfo.id_token}`
  //   } catch (err) { }
  // }

  useEffect(() => {

    // init message banner: 
    // There is a certain probability that the message banner of appkit4 will not display when first called, maybe is it a bug ?
    toaster.notify(<></>)
  }, [])

  return <>
    <Header
      className="mtid-app-header"
      type="transparent"
      titleTemplate={() => <div className="sub-pwc-logo">Managed Threat Intelligence & Detection</div>}
      // userTemplate={() => storedUserInfo?.uid && (
      //   <PanelButton
      //     className="avatar"
      //     popupElement={<Logout />}
      //   >
      //     {storedUserInfo?.uid?.slice(0, 2)}
      //   </PanelButton>
      // )}
    >
    </Header>

    <div className="appMain">
      <div className="appContent">
        <Outlet />
      </div>
    </div>

    <Footer
      content="© 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a class='ap-link' href='https://www.pwc.com/structure' 
target='_blank'>www.pwc.com/structure</a> for further details."
      type="text"
    />
  </>
}
