import { createStore } from "redux";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducers from "../reducer";

const encrypt = createTransform(
  inboundState => {

    let state = ''
    if (inboundState) {
      state = inboundState
      try {
        state = encodeURIComponent(JSON.stringify(inboundState))
      } catch (err) { }
    }

    return window.btoa(state);
  }, 
  outboundState => {
    let state = ''
    if (outboundState) {
      state = outboundState
      try {
        state = JSON.parse(decodeURIComponent(window.atob(outboundState)))
      } catch (err) { }
    }

    return state;
  }
);

const persistConfig = {
  key: "root",
  storage,
  transforms: [encrypt]
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = createStore(
  persistedReducer
);
export const persistor = persistStore(store);
