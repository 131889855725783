import { Notification, toaster } from "@appkit4/react-components";

const createMessageElement = (status, message, title) => {
  return <Notification status={status} title={title} message={message} />;
}

export class Message {
  constructor(duration = 3000) {
    this.$duration = duration;
  }

  success = (message, title, ...args) => {
    return toaster.notify(createMessageElement("success", message, title), {
      position: "topCenter",
      duration: this.$duration,
      ...args
    });
  };

  warning = (message, title, ...args) => {
    return toaster.notify(createMessageElement("warning", message, title), {
      position: "topCenter",
      duration: this.$duration,
      ...args,
      id: 'xxb'
    });
  };

  error = (message, title, ...args) => {
    return toaster.notify(createMessageElement("error", message, title), {
      position: "topCenter",
      duration: this.$duration,
      ...args
    });
  };

  withCustomStatus = (message, title, icon, ...args) => {
    return toaster.notify(createMessageElement(icon, message, title), {
      position: "topCenter",
      duration: this.$duration,
      ...args
    });
  };
}

export const message = new Message()
