import moment from 'moment'
import "moment-timezone"
import { useSelector, useDispatch } from 'react-redux'

import { updateTimezone, updateUser } from '../actions'

/**
 * @returns momentDate
 */
export const useNow = () => {
  const timezone = useSelector(state => state.timezone)
  const now = moment().tz(timezone)
  const diff = (moment().utcOffset() - moment().tz(timezone).utcOffset()) * 60 * 1000
  return [now, diff]
}

export const useUserInfo = () => {
  const userInfo = useSelector(state => state.userInfo)
  const dispatch = useDispatch()
  return [userInfo, userInfo => dispatch(updateUser(userInfo))]
}

export const useTimezone = () => {
  const timezone = useSelector(state => state.timezone)
  const dispatch = useDispatch()
  return [timezone, (timezone) => {
    return dispatch(updateTimezone(timezone))
  }]
}
